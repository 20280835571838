











































import {Component, Prop, Vue, Watch} from 'vue-property-decorator'
import {tourStore} from '@/store/modules/tour'
import TourBackground from '@/components/TourBackground.vue'
import {profileStore} from "@/store/modules/profile";
import {requestsStore} from "@/store/modules/requests/RequestsStore";
import {Action} from "vuex-class";
import {RolesList} from "@/domain/model/types";

@Component({
  name: 'tour'
})
export default class Tour extends Vue {
  @Prop() value!: boolean

  appRoot: HTMLElement | null = null
  childrenBackground: any[] = []

  @Action acceptRequest;

  get show() {
    return this.value;
  }

  set show(value: boolean) {
    this.$emit('input', value);
  }

  get title() {
    return `Step ${this.tourNumber}/${this.tourLimit}`
  }

  get back() {
    return this.tourNumber > 1 ? 'Back' : ''
  }

  get classTour() {
    const classList: string[] = []
    if (tourStore.tourAddition && 'arrow' in tourStore.tourAddition) {
      classList.push(`arrow-${tourStore.tourAddition.arrow.side}`)
      classList.push(`arrow-position-${tourStore.tourAddition.arrow.position}`)
    } else {
      classList.push(`arrow-${this.arrow.side}`)
      classList.push(`arrow-position-${this.arrow.position}`)
    }
    return classList.join(' ')
  }

  get nextText() {
    return this.tour.next
  }

  get styleTour() {
    const styleList: string[] = []
    if (this.tourBusy) {
      styleList.push(`display: none`)
    }
    const position = (tourStore.tourAddition && 'position' in tourStore.tourAddition) ?
        tourStore.tourAddition.position :
        this.position
    for (const [key, val] of Object.entries(position)) {
      if (typeof(val) === 'number') {
        styleList.push(`${key}: ${val}px`)
      } else if (typeof val === 'object' && val !== null && !Array.isArray(val) && val !== {}) {
        for (const [key1, val1] of Object.entries(val)) {
          if (key1.charAt(0) === '_' && this.selectedObj) {
            let dest = Math.round(this.selectedObj[key1.substring(1)]) + val1
            styleList.push(`${key}: ${dest}px`)
          } else if (this.relativeObj) {
            let dest = Math.round(this.relativeObj[key1]) + val1
            styleList.push(`${key}: ${dest}px`)
          }
        }
      } else if (typeof val === 'string' || val instanceof String) {
        styleList.push(`${key}: ${val}`)
      }
    }
    return styleList.join(';')
  }

  get tourLimit() {
    return tourStore.tourLimit
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  get tour() {
    return tourStore.tour
  }

  get relativeObj() {
    return tourStore.tourRelativeObj
  }

  get selectedObj() {
    return tourStore.tourSelectedObj
  }

  get content() {
    return this.tour.text
  }

  get position() {
    return this.tour.position
  }

  get backgrounds() {
    return this.tour.backgrounds
  }

  get router() {
    return this.tour?.router
  }

  get arrow() {
    return this.tour?.arrow
  }

  get tourBusy() {
    return tourStore.tourBusy
  }

  get tourAction() {
    return tourStore.tourAction
  }

  private onClose() {
    this.removeBackgrounds()
    tourStore.changeTour(0)
  }

  private async onNext() {
    const next: number = this.tourNumber + 1
    await tourStore.changeTour(next)
    if (next > this.tourLimit) {
      this.removeBackgrounds()
    }
  }

  private async onBack() {
    const next: number = this.tourNumber - 1
    await tourStore.changeTour(next)
  }

  private removeBackgrounds() {
    for (const background of this.childrenBackground) {
      this.appRoot?.removeChild(background.$el)
      background?.$destroy()
    }
    this.childrenBackground = []
  }

  private showBackground() {
    this.removeBackgrounds()
    if (tourStore.tourAction) {
      return
    }
    for (const background of this.backgrounds) {
      const backgroundBody = Vue.extend(TourBackground)
      const child = new backgroundBody({
        propsData: { position: background }
      }).$mount();
      this.childrenBackground.push(child)
      this.appRoot?.appendChild(child.$el)
    }
  }

  private async tourCheckAction() {
    if (await tourStore.checkTour({tourNumber: 7, role: RolesList.admin}) && !tourStore.tourTextSessionId) {
      await tourStore.createChat()
    }
  }

  private async tourRouter() {
    if (await tourStore.checkTour({tourNumber: 5, role: RolesList.admin})) {
      await this.$router.push({name: 'contact-info', params: {contactId: tourStore.tourAssociate!.id! }})
    }
    if (await tourStore.checkTour({tourNumber: 8, role: RolesList.admin})) {
      await this.$router.push({
        name: 'inbox-request',
        params: {chatId: tourStore.tourTextSessionId ? tourStore.tourTextSessionId : '' },
        query: {type: 'inbox', subtype: 'personal'}
      })
    }
  }

  private async initTour() {
    this.removeBackgrounds()
    if (tourStore.tourAction) {
      return
    }
    await this.tourCheckAction()

    if (this.router === null) {
      await this.tourRouter()
    } else if (this.$route.name !== this.router!.name) {
      const {name, params, query} = this.router
      const queryParameters = {
        ...query as object
      }
      await this.$router.push({ name, params, query: queryParameters })
    }
  }

  @Watch('tourBusy')
  async onChangeTourBusy(after: string) {
    if (after) {
      await this.initTour()
    } else {
      this.showBackground()
    }
  }

  @Watch('tourAction')
  async onChangeTourAction(after: string) {
    if (!after) {
      await this.initTour()
      this.showBackground()
    }
  }

  async mounted() {
    this.appRoot = document.getElementById('app')
    await this.initTour()
    this.showBackground()
  }
}
